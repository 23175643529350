import { mapState, mapActions, mapMutations } from "vuex";
import _ from "underscore";
import { DomHandler } from "@/libs/domhanlder";
import Header from "@/components/Header";
import Content from "@/components/Content";
import Suggestion from "@/components/Suggestion";
import UserInput from "@/components/UserInput";
import Enquete from "@/components/Enquete";

export default {
  data: function() {
    return {
      isReady: false,
      isWindowOpen: "",
      isWindowOpenPc: "",
      isWindowOpenSp: "",
      launcherTitle: "",
      showLauncherLeftIcon: "",
      showLauncherRightIcon: "",
      isSP: false,
      isIphone: false,
      userInputChoices: {},
      userChoices: {},
      isFocusMessageInput: false,
      query: "",
      icon: "",
      iconMotion: "",
      buttonMotion: "",
      customStyle: {
        fontFamily: ""
      }
    };
  },
  computed: {
    isSuggestion() {
      return (
        this.stepId === "init" &&
        this.isFocusMessageInput &&
        !_.isEmpty(this.userInputChoices) &&
        this.constObj.HAS_AUTO_COMPLETE
      );
    },
    isEnqueteOpen() {
      return this.$store.state.enquete.isEnqueteOpen;
    },
    enqueteMode() {
      return this.$store.state.enquete.enqueteMode;
    },
    getEnquetes() {
      return this.$store.state.constObj.ENQUETES;
    },
    stepId() {
      return this.$store.state.botAction.stepId;
    },
    connectStatus() {
      return this.$store.state.operatorAction.connectStatus;
    },
    constObj() {
      return this.$store.state.constObj;
    },
  },
  methods: {
    toggleWindow() {
      this.isWindowOpen = !this.isWindowOpen;
    },
    getMotionClass(target) {
      return [
        target.motion,
        target.duration,
        {
          animate__infinite: target.infinite,
        },
      ];
    },
    toggleIsReady() {
      this.isReady = !this.isReady;
    },
    getResourceImg(fileName) {
      return require('@product/resource/' + fileName);
    },
    clearMessages() {
      this.resetMessages();
    },
    scrollTop() {
      const element = "#message0";
      const duration = 0;
      const options = { offset: -60 };
      this.$scrollTo(element, duration, options);
    },
    setDevice(ua) {
      this.isSP = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        ua
      );
      this.isIphone = /iPhone/i.test(ua);
    },
    initWindowOpen(isSP) {
      this.isWindowOpen = isSP ? this.isWindowOpenSp : this.isWindowOpenPc;
    },
    async onChangeInputMessage(text) {
      const isGreeting = await this.isGreetingMessages(text);
      if (this.connectStatus === "unconnected" && !isGreeting) {
        this.query = text;
        this.userInputChoices = await this.searchAutoComplete(text);
        this.userChoices = structuredClone(this.userInputChoices)
        this.userInputChoices = await this.hilightAutoCompleteText(
          text,
          this.userInputChoices
        );
      } else {
        this.userChoices = {};
        this.userInputChoices = {};
      }
    },
    async hilightAutoCompleteText(text, choices) {
      const synonyms = await this.searchSynonym(text);
      let regText = synonyms;
      if (synonyms && synonyms.length) regText = synonyms.join("|");
      for (let key of Object.keys(choices)) {
        const match = choices[key].match(new RegExp(regText, "ig"));
        if (regText && match) {
          for (const keyText of match) {
            const hilightText = `<span class='hilight'>${keyText}</span>`;
            choices[key] = choices[key].replace(keyText, hilightText);
          }
        }
      }
      return choices;
    },
    async sendMessage(text) {
      this.setLocation();
      this.clearSuggestion();
      const cleanedText = text.replace(/\n+$/g, "");
      this.distributeTextMessge(cleanedText);
    },
    async selectAnswer(choice, key) {
      this.setLocation();
      const answer = {
        keyName: _.isString(key) ? key : Number(key) + 1,
        choice: choice
      };
      await this.distributeChoiceMessage(answer);
    },
    async selectSuggest(key) {
      this.setLocation();
      const answer = {
        keyName: _.isString(key) ? key : Number(key) + 1,
        choice: this.userChoices[key]
      };
      await this.distributeChoiceMessage(answer);
    },
    updateIsFocusMessageInput(bool) {
      this.isFocusMessageInput = bool;
    },
    updateInitData() {
      const constObj = this.constObj;
      this.isWindowOpenPc = constObj.IS_WINDOW_OPEN_PC;
      this.isWindowOpenSp = constObj.IS_WINDOW_OPEN_SP;
      this.launcherTitle = constObj.LAUNCHER_TITLE;
      this.showLauncherLeftIcon = constObj.SHOW_LAUNCHER_LEFT_ICON;
      this.showLauncherRightIcon = constObj.SHOW_LAUNCHER_RIGHT_ICON;
      this.hasBotIcon = constObj.HAS_BOT_ICON;
      this.icon = constObj.LAUNCHER_ICON;
      this.iconName = constObj.DEFAULT_BOT_ICON;
      this.iconMotion = constObj.LAUNCHER_ICON_MOTION;
      this.buttonMotion = constObj.LAUNCHER_BUTTON_MOTION;
      this.customStyle = {
        fontFamily: constObj.CUSTOM_STYLE.FONT_FAMILY
      };
      this.isReady = true;
    },
    clearSuggestion() {
      this.userChoices = {};
      this.userInputChoices = {};
    },
    updateSuggestionParams(choice, key) {
      const ticketData = {
        results: Object.keys(this.userInputChoices),
        query: this.query
      };
      this.updateTicketItems(ticketData);
    },
    setLocation() {
      if (!this.$store.state.ticket.ticket.location_href) {
        const ticketData = {
          location_href: location.href,
          location_hostname: location.hostname,
          location_pathname: location.pathname
        };
        this.updateTicketItems(ticketData);
      }
    },
    ...mapActions([
      "initChatWindow",
      "resetMessages",
      "reConnection",
      "distributeChoiceMessage",
      "distributeTextMessge",
      "searchAutoComplete",
      "searchSynonym",
      "isGreetingMessages",
      "updateTicketItems"
    ])
  },
  components: {
    Header,
    Content,
    Suggestion,
    UserInput,
    Enquete
  },
  mounted: function() {
    document.querySelector("body").addEventListener("click", e => {
      if (this.$refs.inputContent) {
        if (!this.$refs.inputContent.contains(e.target)) {
          this.isFocusMessageInput = false;
        }
      }
    });
    this.$store.state.enquete.isEnqueteOpen = false;
  },
  created: async function() {
    await this.initChatWindow();
    this.updateInitData();
    this.reConnection();
    this.setDevice(navigator.userAgent);
    this.initWindowOpen(this.isSP);
  }
};
