var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "saichat-user-input", style: _vm.styleField },
    [
      _c("form", { staticClass: "saichat-user-input__form" }, [
        _c("textarea", {
          ref: "sendMessageInput",
          staticClass: "saichat-user-input__form__field",
          class: { lessFont: _vm.isSP },
          attrs: { type: "text", placeholder: _vm.placeholder },
          domProps: { value: _vm.inputMessage },
          on: {
            input: [
              function ($event) {
                _vm.inputMessage = $event.target.value
              },
              _vm.onInput,
            ],
            click: function ($event) {
              $event.preventDefault()
              return _vm.onFocusInput.apply(null, arguments)
            },
            keydown: _vm.onKeydownInput,
            keyup: _vm.onKeyupInput,
            compositionstart: _vm.onConpositionStart,
            compositionend: _vm.onCompositionend,
          },
        }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "saichat-user-input__form__send",
            style: _vm.styleSend,
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.sendMessage.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "svg",
              {
                class: "send-icon",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 512 512",
                  "svg-inline": "",
                  role: "presentation",
                  focusable: "false",
                  tabindex: "-1",
                },
              },
              [
                _c("path", {
                  staticClass: "st0",
                  attrs: {
                    d: "M504.464 81.665l-23.742 5.313-7.215 1.625-282.921 63.515L.098 194.774l.114.082-.212.048 101.703 75.394 38.687 160.195 103.117-57.926 62.336 47.629 7.687 5.898.012-.015.129.098L511.128 90.821c2.742-4.656-1.394-10.336-6.664-9.156zM305.519 384.297l-82.774-63.168-27.918-21.37 256.827-163.695-130.053 220.948-16.082 27.285zm-79.75-34.117l-38.015 21.32-29.817 16.582 27.93-68.492 28.801 22.023 11.101 8.567zm-75.527.387l-20.461-84.445 235.164-99.934-133.984 85.378-54.141 34.422-.023.062-.122-.125-14.5 35.64-11.933 29.002zM66.687 209.02l303.918-68.293L118.82 247.735l-31.762-23.507-20.371-15.208z",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }