var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "saichat-header" }, [
    _vm.showHeaderIcon
      ? _c("div", { staticClass: "saichat-header__icon" })
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "saichat-header__title" }, [
      _vm._v(_vm._s(_vm.chatWindowTitle)),
    ]),
    _vm._v(" "),
    _vm.showHeaderSubTitle
      ? _c("div", { staticClass: "saichat-header__title__sub" }, [
          _vm._v(
            "\n    " + _vm._s(_vm.constObj.CHAT_WINDOW_SUB_TITLE) + "\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "a",
      {
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.clearMessages.apply(null, arguments)
          },
        },
      },
      [
        _vm.constObj.HEADER_RESET.type === "icon"
          ? _c(
              "span",
              { staticClass: "saichat-header__btn reset-icon" },
              [
                _c("font-awesome-icon", {
                  class: _vm.constObj.HEADER_RESET.class,
                  attrs: { icon: _vm.constObj.HEADER_RESET.src },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.constObj.HEADER_RESET.type == "text"
          ? _c("span", { staticClass: "saichat-header__btn reset-text" }, [
              _c("span", [_vm._v(_vm._s(_vm.constObj.HEADER_RESET.src[0]))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.constObj.HEADER_RESET.type == "img"
          ? _c("span", { staticClass: "saichat-header__btn reset-img" }, [
              _c("img", {
                attrs: {
                  src: _vm.getResourceImg(_vm.constObj.HEADER_RESET.src[0]),
                },
              }),
            ])
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.toggleWindow.apply(null, arguments)
          },
        },
      },
      [
        _vm.constObj.HEADER_MINIMIZE.type === "icon"
          ? _c(
              "span",
              { staticClass: "saichat-header__btn minimize-icon" },
              [
                _c("font-awesome-icon", {
                  class: _vm.constObj.HEADER_MINIMIZE.class,
                  attrs: { icon: _vm.constObj.HEADER_MINIMIZE.src },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.constObj.HEADER_MINIMIZE.type === "img"
          ? _c("span", { staticClass: "saichat-header__btn minimize-img" }, [
              _c("img", {
                attrs: {
                  src: _vm.getResourceImg(_vm.constObj.HEADER_MINIMIZE.src[0]),
                },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.constObj.HEADER_MINIMIZE.type == "text"
          ? _c("span", { staticClass: "saichat-header__btn minimize-text" }, [
              _c("span", [_vm._v(_vm._s(_vm.constObj.HEADER_MINIMIZE.src[0]))]),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }