var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "saichat-suggestion" }, [
    _c(
      "ul",
      _vm._l(_vm.userInputChoices, function (choice, key) {
        return _c(
          "li",
          { key: key, staticClass: "saichat-suggestion__choice" },
          [
            _c("a", {
              staticClass: "saichat-suggestion__choice__link",
              domProps: { innerHTML: _vm._s(choice) },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.selectSuggest(choice, key)
                },
              },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }