import { mapState } from "vuex";
import _ from "underscore";
import { DomHandler } from "common/libs/domhanlder";
import MessageArea from "@/components/MessageArea";

export default {
  props: ["isIphone"],
  data: function() {
    return {
      showCopyright: false,
      copyright: ""
    };
  },
  computed: {
    messages() {
      return this.$store.state.messagesHandler.messages;
    },
    connectStatus() {
      return this.$store.state.operatorAction.connectStatus;
    },
    ...mapState(["constObj"])
  },
  components: {
    MessageArea
  },
  mounted: function() {
    if (this.isIphone) {
      DomHandler.preventOutsideScrolling(this.$refs.messagesContent);
    }
  },
  created: function() {
    const constObj = this.constObj;
    this.showCopyright = constObj.SHOW_COPYRIGHT;
    this.copyright = constObj.COPYRIGHT;
  },
  watch: {
    messages() {
      this.$nextTick(() => this.scrollBottom());
    }
  },
  methods: {
    selectAnswer(choice, key) {
      this.$emit("selectAnswer", choice, key);
    },
    scrollBottom() {
      if (this.getScrollIndex() === 0) {
        this.$emit("scrollTop");
      } else {
        // メッセージのやり取り中に bot と user が同時に更新された場合に二重にスクロールされるのを防ぐ
        if (this.messages[this.messages.length - 1].from == "bot") {
          const element = `#message${this.getScrollIndex()}`;
          this.$scrollTo(element);
        }
      }
    },
    getScrollIndex() {
      const messagesIds = [];
      this.messages.filter(function(message, index) {
        if (message.from != "bot") {
          messagesIds.push(index);
        }
      });
      if (messagesIds.length > 0) {
        return messagesIds[messagesIds.length - 1];
      } else {
        return 0;
      }
    }
  }
};
